import { XMarkIcon, CheckIcon } from "@heroicons/react/24/solid";
import { Modal, Box } from "@mui/material";
import { useState } from "react";
import {
  useRefresh,
  useRecordContext,
  useDataProvider,
  useNotify,
} from "react-admin";
import { Button } from "../Elements/Button";
import { useMutation } from "react-query";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "background.paper",
  border: "1px",
  borderRadius: "21px",
  boxShadow: 24,
  p: 3,
};
export const UpdateButtons = () => {
  const refresh = useRefresh();
  const notify = useNotify();

  const record = useRecordContext();
  const dataProvider = useDataProvider();

  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState("");

  const { mutate, isLoading } = useMutation(
    () => dataProvider.updateClassRequest(record.id, selected),
    {
      onSuccess: () => {
        refresh();
      },
      onError: () => {
        notify("Error: Please try again", { type: "warning", undoable: false });
      },
    }
  );
  const handleOpenDecline = () => {
    setOpen(true);
    setSelected("Declined");
  };
  const handleOpenApprove = () => {
    setOpen(true);
    setSelected("Approved");
  };

  const handleClose = () => setOpen(false);

  if (!record || isLoading) return null;

  if (
    record.status_kelas === "Approved" ||
    record.status_kelas === "Declined"
  ) {
    return null;
  }

  return (
    <div className="flex justify-center pb-8 space-x-10">
      <Button
        variant="decline"
        leftIcon={<XMarkIcon width={16} />}
        onClick={handleOpenDecline}
      >
        <p className="">Decline</p>
      </Button>
      <Button
        variant="approve"
        leftIcon={<CheckIcon width={16} />}
        onClick={handleOpenApprove}
      >
        <p className="">Approve</p>
      </Button>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <div className="flex flex-col text-center font-medium">
            {selected.slice(0, -1) + " this class?"}
            <div className="flex space-x-8 mt-8">
              <button
                onClick={handleClose}
                className="w-1/2 text-center text-[#006600]"
              >
                Cancel
              </button>
              <button
                disabled={isLoading}
                onClick={() => {
                  mutate();
                  handleClose();
                }}
                className="w-1/2 bg-[#006600] text-white border rounded-[20px] px-3 py-2"
              >
                {selected.slice(0, -1)}
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};
