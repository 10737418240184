import {
  List,
  SingleFieldList,
  TextField,
  EditButton,
  DeleteWithConfirmButton,
  ArrayField,
  ChipField,
  ShowButton,
  BooleanField,
  TextInput,
} from "react-admin";
import { MyDatagrid } from "../Elements";
export const KelasList = (props) => {
  const classFilters = [
    <TextInput label="Search" key={"class search"} source="q" alwaysOn />,
  ];
  return (
    <List {...props} filters={classFilters}>
      <MyDatagrid>
        <TextField source="id" sortable={false} />

        <TextField source="nama" sortable={false} />
        <TextField source="oleh" sortable={false} />

        <ArrayField source="tutor" sortable={false}>
          <SingleFieldList linkType={false}>
            <ChipField source="user.nama" />
          </SingleFieldList>
        </ArrayField>

        <TextField
          source="jumlah_tutee"
          label="Total Tutees"
          sortable={false}
        />

        <TextField source="rating" sortable={false} />
        <TextField source="jumlah_sesi" sortable={false} />
        <BooleanField source="is_active" sortable={false} />
        <ShowButton />
        <EditButton />
        <DeleteWithConfirmButton />
      </MyDatagrid>
    </List>
  );
};
