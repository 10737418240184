export const WEEKDAYS_CHOICES = [
  {
    name: "15.30-17.30",
  },
  {
    name: "19.30-21.30",
  },
];
export const WEEKEND_CHOICES = [
  { name: "09.30-11.30" },
  { name: "13.00-15.00" },
  { name: "15.30-17.30" },
  { name: "19.30-21.30" },
];
export const DAYS = [
  "MONDAY",
  "TUESDAY",
  "WEDNESDAY",
  "THURSDAY",
  "FRIDAY",
  "SATURDAY",
  "SUNDAY",
];
export const SUBJECTS = [
  { name: "Matematika" },
  { name: "Fisika" },
  { name: "Kimia" },
  { name: "Biologi" },
  { name: "Geografi" },
  { name: "Sejarah" },
  { name: "Ekonomi" },
  { name: "Sosiologi" },
];
