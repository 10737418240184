import { useState, useEffect } from "react";
import {
  Show,
  SimpleShowLayout,
  TextField,
  UrlField,
  ReferenceField,
  ArrayField,
  Datagrid,
  DateField,
  ChipField,
  FunctionField,
  useGetRecordId,
  useDataProvider,
  Loading,
} from "react-admin";
import toast from "react-hot-toast";

export const TransactionShow = () => {
  const transactionId = useGetRecordId();
  const [record, setRecord] = useState(null);
  const dataProvider = useDataProvider();
  useEffect(() => {
    const fetchRecord = async () => {
      try {
        const { data } = await dataProvider.getOne("transactions", {
          id: transactionId,
        });

        setRecord(data);
      } catch (error) {
        // Handle error
        toast.error(error.message);
      }
    };

    fetchRecord();
  }, [dataProvider]);
  return !!record ? (
    <div className="mb-4 bg-white rounded-lg shadow-md mt-16">
      <SimpleShowLayout record={record}>
        <TextField source="id" />
        <ReferenceField source="user.id" reference="users" link="show">
          <TextField source="nama" />
        </ReferenceField>
        {!!record.kelompok && <TextField source="kelompok" />}
        <TextField source="status" />
        <TextField source="taking_class.nama" label="Class" />
        <TextField source="paket_class.nama" label="Paket Kelas" />
        <TextField source="paket_class.harga" label="Harga Paket Kelas" />
        {!!record.jumlah_cicilan_class && (
          <TextField
            source="jumlah_cicilan_class.cicilan"
            label="Jumlah Cicilan"
          />
        )}
        {!!record.kelipatan_cicilan_class && (
          <TextField
            source="kelipatan_cicilan_class.kelipatan"
            label="Kelipatan Cicilan"
          />
        )}
        {!!record.motivations && <TextField source="motivations" />}
        {!!record.grade_level && <TextField source="grade_level" />}
        {!!record.details_of_topics_to_learn && (
          <TextField source="details_of_topics_to_learn" />
        )}
        {!!record.learning_challenges && (
          <TextField source="learning_challenges" />
        )}
        {!!record.source_of_information && (
          <FunctionField
            label="Source of Information"
            render={(record) =>
              record?.source_of_information?.map((item, index) => (
                <ChipField
                  key={index}
                  record={{ id: item, chip: item }}
                  source="chip"
                />
              ))
            }
          />
        )}

        {!!record.study_schedule && (
          <ArrayField source="study_schedule">
            <Datagrid bulkActionButtons={false}>
              <TextField source="preferred_day" />
              <TextField source="preferred_time" />
            </Datagrid>
          </ArrayField>
        )}

        <TextField source="real_price" label="Real Price" />
        <TextField source="discount" />
        <UrlField source="invoice_url" />
        <ArrayField source="subject_session">
          <Datagrid bulkActionButtons={false}>
            <TextField source="subject" />
            <TextField source="session" />
          </Datagrid>
        </ArrayField>
        <TextField source="payment_method" />
        <DateField source="paid_at" />
        <UrlField source="whatsapp_url" label="Whatsapp" />
      </SimpleShowLayout>
    </div>
  ) : (
    <Loading />
  );
};
