import { List, TextField, ShowButton } from "react-admin";
import { ArrowSmallRightIcon } from "@heroicons/react/24/solid";
import { StatusField } from "../CustomFields";
import { searchFilters } from "../../filters";
import { MyDatagrid } from "../Elements";

export const CreatedClassList = (props) => {
  return (
    <List {...props} filters={searchFilters}>
      <MyDatagrid>
        <TextField source="id" label="No" sortable={false} />
        <TextField source="nama" label="Nama Kelas" sortable={false} />
        <TextField
          source="jumlah_tutee"
          label="Jumlah Tutee"
          sortable={false}
        />
        <StatusField label="Status" />
        <ShowButton
          icon={<ArrowSmallRightIcon width={24} />}
          alignIcon="right"
          label="Show Details"
        />
      </MyDatagrid>
    </List>
  );
};
