import {
  List,
  TextField,
  ShowButton,
  ReferenceField,
  EditButton,
  TextInput,
  SelectInput,
} from "react-admin";

import { MyDatagrid } from "../Elements";
import { KELAS_TYPES, STATUS_CHOICES } from "src/constants";
export const TransactionList = (props) => {
  const transactionsFilters = [
    <TextInput label="Nama Kelompok" source="kelompok" alwaysOn />,
    <TextInput label="Nama Tutee" source="name" />,
    <TextInput label="Paket Kelas" source="paket_kelas" />,
    <SelectInput
      label="Status Order"
      source="status_order"
      choices={STATUS_CHOICES}
    />,
    <SelectInput label="Tipe Kelas" source="tipe" choices={KELAS_TYPES} />,
  ];

  return (
    <List {...props} filters={transactionsFilters}>
      <MyDatagrid>
        <TextField source="id" sortable={false} />

        <TextField source="user.nama" label="Nama Tutee" sortable={false} />
        <TextField source="kelompok" sortable={false} />
        <TextField source="status" sortable={false} />
        <TextField source="taking_class.nama" label="Class" sortable={false} />
        <TextField
          source="paket_class.nama"
          label="Paket Kelas"
          sortable={false}
        />
        <TextField
          source="paket_class.harga"
          label="Harga Paket Kelas"
          sortable={false}
        />
        <TextField
          source="jumlah_cicilan_class.cicilan"
          label="Jumlah Cicilan"
          sortable={false}
        />
        <TextField
          source="kelipatan_cicilan_class.kelipatan"
          label="Kelipatan Cicilan"
          sortable={false}
        />
        <TextField source="real_price" label="Real Price" sortable={false} />
        <ShowButton />
        <EditButton />
      </MyDatagrid>
    </List>
  );
};
