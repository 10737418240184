import {
  List,
  TextField,
  EditButton,
  DeleteWithConfirmButton,
  BooleanField,
  ChipField,
  SingleFieldList,
  TextInput,
  UrlField,
  ShowButton,
  ArrayField,
  BooleanInput,
  AutocompleteInput,
} from "react-admin";
import { MyDatagrid } from "../Elements";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import axios from "axios";

export const TuteeList = (props) => {
  const [classes, setClasses] = useState([]);
  useEffect(() => {
    const fetchRecord = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_RESOURCE_API_URL}/classes/list`
        );

        setClasses(
          data?.map(({ nama }) => {
            return { id: nama, name: nama };
          })
        );
      } catch (error) {
        toast.error(error.message);
      }
    };
    fetchRecord();
  }, []);
  const tuteeFilters = [
    <TextInput label="Search" key={"search"} source="q" alwaysOn />,
    <AutocompleteInput
      label="Class name"
      key={"class name"}
      source="q"
      choices={classes}
    />,
    <BooleanInput
      label="Is active"
      source="active_status"
      helperText="Toggle the button to return a list of active tutees."
      defaultValue={true}
    />,
  ];

  return (
    <List {...props} filters={tuteeFilters}>
      <MyDatagrid>
        <TextField source="id" sortable={false} />
        <TextField source="user.nama" label="User" sortable={false} />
        <TextField
          source="user.asal_sekolah"
          label="Asal Sekolah"
          sortable={false}
        />

        <UrlField source="notion_url" />

        <ArrayField
          source="user.kelas_berlangsung"
          label="Kelas Berlangsung"
          sortable={false}
        >
          <SingleFieldList linkType={false}>
            <ChipField source="nama" />
          </SingleFieldList>
        </ArrayField>

        <BooleanField source="active_status" sortable={false} />

        <ShowButton />
        <EditButton />
        <DeleteWithConfirmButton />
      </MyDatagrid>
    </List>
  );
};
