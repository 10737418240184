import { Chip } from "@mui/material";
import { useListContext } from "react-admin";

export const Subjects = () => {
  const { data } = useListContext();
  return (
    <ul className="flex gap-1">
      {data.map((subject) => (
        <Chip key={subject} label={subject} />
      ))}
    </ul>
  );
};
