import {
  Admin,
  Resource,
  combineDataProviders,
  usePermissions,
} from "react-admin";

import { authProvider } from "src/provider";
import { Dashboard, MyLayout, MyLogin, myTheme } from "src/components";

import Tutee from "../Tutee";
import Tutor from "../Tutor";
import User from "../User";
import Kelas from "../Kelas";
import Transaction from "../Transaction";
import Coupon from "../Coupon";
import ClassMaker from "../ClassMaker";

import ClassCreation from "../ClassCreation";

import { customDataProvider } from "src/provider/dataProvider";
import { dummyProvider } from "src/provider/dataProvider";

const dataProvider = combineDataProviders((resource) => {
  switch (resource) {
    case "class-creation":
      return dummyProvider;
    case "classmaker":
      return dummyProvider;
    default:
      return customDataProvider;
  }
});

export const AdminStore = () => {
  // const { permissions } = usePermissions();
  return (
    <Admin
      dataProvider={dataProvider}
      layout={MyLayout}
      dashboard={Dashboard}
      theme={myTheme}
      authProvider={authProvider}
      loginPage={MyLogin}
    >
      {(permissions) =>
        permissions === "ADMIN" ? (
          <>
            <Resource name="users" {...User} />
            <Resource name="tutees" {...Tutee} />
            <Resource name="tutors" {...Tutor} />
            <Resource name="classes" {...Kelas} />
            <Resource name="classrequests" {...ClassCreation} />
            <Resource name="transactions" {...Transaction} />
            <Resource name="coupons" {...Coupon} />
          </>
        ) : (
          <>
            <Resource name="classes" {...ClassMaker} />
          </>
        )
      }
    </Admin>
  );
};
