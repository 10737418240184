import { Box, Typography } from "@mui/material";

export const Dashboard = () => {
  return (
    <div>
      <Box padding={5}>
        <Typography variant="h4" fontStyle={"bold"}>
          Taman School CMS Dashboard
        </Typography>
      </Box>
    </div>
  );
};
