import { Edit, useRecordContext } from "react-admin";
import { StatusField } from "../CustomFields";
import { CheckBoxField } from "./CheckBoxField";
import { PackageField } from "./PackageField";
import { CustomClassForm } from "./CustomClassForm";
import { UpdateButtons } from "./UpdateButtons";

export const ClassCreationEdit = (props) => {
  return (
    <Edit {...props} mutationMode="optimistic" className="my-8">
      <ClassCreataionDetails />
      <UpdateButtons />
      {/* <Loading /> */}
    </Edit>
  );
};
const RecordField = ({ label, value }) => (
  <div className="space-y-2">
    <p className="text-[#757575]">{label}</p>
    <p className="text-black border rounded-lg px-3 py-2">{value}</p>
  </div>
);

const ClassCreataionDetails = () => {
  const record = useRecordContext();
  return (
    <div className="px-14 py-8 flex flex-col space-y-8">
      <RecordField label="Class Name" value={record.nama} />
      <div className="space-y-2">
        <p className="text-[#757575]">Foto</p>
        <img src={record.foto} alt="Foto" />
      </div>
      <div className="space-y-2">
        <p className="text-[#757575]">Status</p>
        <StatusField size="large" />
      </div>
      <RecordField label="By" value={record.oleh} />
      <RecordField label="Class Description" value={record.deskripsi} />
      <RecordField label="About This Class" value={record.who_is_it_for} />
      <CheckBoxField
        type={"tipe_kelas"}
        label="Class Type"
        value={record.tipe_kelas}
      />
      <CheckBoxField
        type={"tipe_pembayaran"}
        label="Payment Type"
        value={record.tipe_pembayaran}
      />
      <RecordField label="Total Session" value={record.jumlah_sesi} />
      <PackageField
        label={"Class Packages"}
        symbol={"Rp"}
        value={record.paket_kelas}
      />

      {record.tipe_pembayaran === "Top-down" && (
        <PackageField
          label={"Installment Options"}
          symbol={"%"}
          value={record.pilihan_cicilan}
        ></PackageField>
      )}

      {record.tipe_pembayaran === "Bottom-up" && (
        <PackageField
          label={"Session Packages"}
          symbol={"%"}
          value={record.paket_sesi}
        ></PackageField>
      )}

      <CustomClassForm value={record.custom_form} />
    </div>
  );
};
