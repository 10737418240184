import { Button, TextField } from "@mui/material";
import { useState } from "react";
import { useLogin, useNotify, Notification } from "react-admin";

export const MyLogin = ({ theme }) => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const login = useLogin();
  const notify = useNotify();
  const handleSubmit = (e) => {
    e.preventDefault();

    login({ email, password }).catch(() => notify("Invalid email or password"));
  };
  return (
    <div className="bg-white min-h-screen flex items-center justify-center flex-col gap-4">
      <p className="text-4xl">Taman School CMS</p>
      <p className="text-3xl">Admin Login</p>
      <form
        onSubmit={handleSubmit}
        className="flex flex-col border border-primary rounded-3xl p-10 w-80 gap-3"
      >
        <TextField
          name="email"
          type="email"
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          name="password"
          type="password"
          label="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button variant="contained" type="submit">
          Login
        </Button>
      </form>
    </div>
  );
};
