import { FormControlLabel, Radio } from "@mui/material";
import { TIPE_KELAS, TIPE_PEMBAYARAN } from "src/constants/types/classCreation";

export const CheckBoxField = ({ type, label, value }) => {
  const choices = type === "tipe_kelas" ? TIPE_KELAS : TIPE_PEMBAYARAN;
  return (
    <div className="space-y-1">
      <p className="text-[#757575]">{label}</p>
      <div className="flex flex-col">
        {choices.map((choice) => {
          return (
            <FormControlLabel
              key={choice}
              label={choice}
              value={value}
              control={
                <Radio
                  sx={{
                    color: "#9E9E9E",
                    "&.Mui-checked": {
                      color: "#6db656",
                    },
                  }}
                  checked={value === choice}
                  value={value}
                  name="radio-buttons"
                  inputProps={{ "aria-label": label }}
                />
              }
            ></FormControlLabel>
          );
        })}
      </div>
    </div>
  );
};
