import { useRecordContext } from "react-admin";
import { Chip } from "@mui/material";

export const StatusField = (props) => {
  const record = useRecordContext();
  const style = record?.status_kelas || record?.status;
  const statusLabel = record?.status_kelas || record?.status || "Active";

  const statusStyles = {
    Pending: {
      backgroundColor: "#F0F3FF",
      color: "#3267E3",
      borderColor: "#B1C5F6",
    },
    Declined: {
      backgroundColor: "#FFF4F2",
      color: "#CB3A31",
      borderColor: "#EEB4B0",
    },
    Approved: {
      backgroundColor: "#F5FFF6",
      color: "#006600",
      borderColor: "#006600",
    },
  };

  const { backgroundColor, color, borderColor } =
    statusStyles[style] || statusStyles["Approved"];

  if (!record) return null;
  return (
    <Chip
      size="small"
      {...props}
      label={statusLabel}
      sx={{
        border: "1px solid",
        backgroundColor: backgroundColor,
        color: color,
        borderColor: borderColor,
        fontSize: "14px",
        textJustify: "center",
        textAlign: "center",
      }}
    />
  );
};
