import {
  TextField,
  UrlField,
  BooleanField,
  Show,
  ImageField,
  TabbedShowLayout,
  ReferenceField,
  ArrayField,
  Datagrid,
  ChipField,
  FunctionField,
} from "react-admin";
import { RatingField } from "../CustomFields";
import { Subjects } from "./Subjects";

export const TutorShow = (props) => {
  return (
    <Show {...props} className="mb-5">
      <TabbedShowLayout>
        <TabbedShowLayout.Tab label={"User Information"}>
          <TextField source="id" />

          <TextField source="user.nama" label="Nama" />
          <TextField source="user.no_telp" label="No HP" />
          <TextField source="user.pekerjaan" />
        </TabbedShowLayout.Tab>

        <TabbedShowLayout.Tab label={"Tutor Information"}>
          <ImageField source="foto" />
          <TextField source="jumlah_class_diajar" />
          <TextField source="description" />
          <TextField source="role" />
          <TextField source="universitas" />
          <TextField source="jurusan" />
          <ReferenceField
            source="desired_class.id"
            reference="classes"
            label="Desired Class"
            link="show"
          >
            <TextField source="nama" />
          </ReferenceField>
          <UrlField
            source="link_cv"
            target="_blank"
            rel="noopener noreferrer"
          />
          <UrlField
            source="link_portfolio"
            target="_blank"
            rel="noopener noreferrer"
          />
          <UrlField
            source="linkedin_url"
            target="_blank"
            rel="noopener noreferrer"
          />

          <RatingField label="Rating" />
          <BooleanField source="is_accepted" />
          <ArrayField source="subjects">
            <Subjects />
          </ArrayField>
          <ArrayField source="schedules">
            <Datagrid bulkActionButtons={false}>
              <TextField source="type" />
              <FunctionField
                label="Schedule"
                render={(record) =>
                  record.schedule.map((item, index) => (
                    <ChipField
                      key={index}
                      record={{ id: item, chip: item }}
                      source="chip"
                    />
                  ))
                }
              />
            </Datagrid>
          </ArrayField>
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
      <div className="h-5" />
    </Show>
  );
};
