import axios from "axios";
import { useEffect, useState } from "react";
import {
  SimpleForm,
  ReferenceArrayInput,
  AutocompleteInput,
  Create,
  NumberInput,
} from "react-admin";

export const CouponCreate = (props) => {
  const [selectedClassId, setSelectedClassId] = useState<number>();
  const [paketClass, setPaketClass] = useState<any>();
  const [cicilan, setCicilan] = useState<any>();
  const [paketSesi, setPaketSesi] = useState<any>();
  const [tipePembayaran, setTipePembayaran] = useState<string>("");

  useEffect(() => {
    if (selectedClassId) {
      axios
        .get(
          `${process.env.REACT_APP_RESOURCE_API_URL}/classes/detail/${selectedClassId}`
        )
        .then((res) => {
          setTipePembayaran(res.data.tipe_pembayaran);

          setPaketClass(
            res.data.paket_kelas.map(({ id, nama }) => ({ id, name: nama }))
          );
          
          if (res.data.tipe_pembayaran === "Top-down") {
            setCicilan(
              res.data.pilihan_cicilan.map(({ id, cicilan }) => ({
                id,
                name: cicilan,
              }))
            );
          }

          if (res.data.tipe_pembayaran === "Bottom-up") {
            setPaketSesi(
              res.data.paket_sesi.map(({ id, sesi }) => ({
                id,
                name: sesi,
              }))
            );
          }
        });
    }
  }, [selectedClassId]);

  return (
    <Create {...props} redirect="list">
      <SimpleForm>
        <ReferenceArrayInput
          source="class_id"
          reference="classes"
          filter={{ summary: true }}
        >
          <AutocompleteInput
            optionText="nama"
            optionValue="id"
            isRequired
            onChange={(id) => setSelectedClassId(id)}
          />
        </ReferenceArrayInput>

        <AutocompleteInput
          source="paket_class"
          choices={paketClass}
          isRequired
        />

        {/* Top-down */}
        {tipePembayaran === "Top-down" && (
          <AutocompleteInput
            source="pilihan_cicilan"
            choices={cicilan}
            isRequired
          />
        )}

        {/* Bottom-up */}
        {tipePembayaran === "Bottom-up" && (
          <AutocompleteInput
            source="paket_sesi"
            choices={paketSesi}
            isRequired
          />
        )}
        <NumberInput source="discount" required />
      </SimpleForm>
    </Create>
  );
};
