import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  Show,
  SimpleShowLayout,
  TextField,
  BooleanField,
  ImageField,
  useDataProvider,
  useGetRecordId,
  Loading,
} from "react-admin";
import toast from "react-hot-toast";

export const UserShow = () => {
  const [record, setRecord] = useState(null);
  const dataProvider = useDataProvider();
  const tutorId = useGetRecordId();
  const CustomTableCell = styled(TableCell)(({ theme }) => ({
    padding: theme.spacing(1.2),
  }));
  useEffect(() => {
    const fetchRecord = async () => {
      try {
        const { data } = await dataProvider.getOne("users", {
          id: tutorId,
        });

        data.id = data.user.id;

        setRecord(data);
      } catch (error) {
        // Handle error
        toast.error(error.message);
      }
    };

    fetchRecord();
  }, [dataProvider]);
  return !!record ? (
    <div className="mb-4 bg-white rounded-lg shadow-md mt-16">
      <SimpleShowLayout record={record}>
        <TextField source="user.id" label="Id" />
        <TextField source="nama" />
        <TextField source="role" />
        <ImageField source="profile_picture" />
        <TextField source="no_telp" label="Nomor Telepon" />
        <TextField source="nama_ortu" label="Nama Orang Tua" />
        <TextField source="asal_sekolah" />
        <TextField source="pekerjaan" />

        <BooleanField source="is_admin" />
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <CustomTableCell>Type</CustomTableCell>
                <CustomTableCell>Payment</CustomTableCell>
                <CustomTableCell>Class</CustomTableCell>
                <CustomTableCell>Promo </CustomTableCell>
                <CustomTableCell>Newsletter </CustomTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow style={{ padding: "0", margin: "0px" }}>
                <CustomTableCell>Email Notification</CustomTableCell>
                <CustomTableCell>
                  <BooleanField source="payment_email_notification" />
                </CustomTableCell>
                <CustomTableCell>
                  <BooleanField source="class_email_notification" />
                </CustomTableCell>
                <CustomTableCell>
                  <BooleanField source="promo_email_notification" />
                </CustomTableCell>
                <CustomTableCell>
                  <BooleanField source="newsletter_email_notification" />
                </CustomTableCell>
              </TableRow>
              <TableRow>
                <CustomTableCell>Website Notification</CustomTableCell>
                <CustomTableCell>
                  <BooleanField source="payment_website_notification" />
                </CustomTableCell>
                <CustomTableCell>
                  <BooleanField source="class_website_notification" />
                </CustomTableCell>
                <CustomTableCell>
                  <BooleanField source="promo_website_notification" />
                </CustomTableCell>
                <CustomTableCell>
                  <BooleanField source="newsletter_website_notification" />
                </CustomTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </SimpleShowLayout>
    </div>
  ) : (
    <Loading />
  );
};
