import axios from "axios";
import { AuthProvider } from "react-admin";
import { refreshToken, isTokenNearExpired } from "src/utils";
import jwtDecode from "jwt-decode";

type TokenValue = {
  role: string;
};

export const authProvider: AuthProvider = {
  login: ({ email, password }) => {
    return axios
      .post(`${process.env.REACT_APP_API_URL}/login/`, {
        email,
        password,
      })
      .then((res) => {
        const token = res.data?.access;
        const refresh = res.data?.refresh;
        localStorage.setItem("token", token);
        localStorage.setItem("refresh", refresh);

        const decodedToken: TokenValue = jwtDecode(token);
        localStorage.setItem("role", decodedToken.role);
      })
      .catch((error) => {
        throw new Error(error);
      });
  },
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      return Promise.reject({ message: "Session expired" });
    }
    // other error code (404, 500, etc): no need to log out
    return Promise.resolve();
  },
  checkAuth: () => {
    const token = localStorage.getItem("token");
    if (token && isTokenNearExpired(token)) {
      return refreshToken()
        .then((res) => {
          return Promise.resolve();
        })
        .catch((error) => {
          return Promise.reject();
        });
    } else if (token && !isTokenNearExpired(token)) {
      return Promise.resolve();
    } else {
      return Promise.reject({ message: "Login Required" });
    }
  },
  getPermissions: () => {
    const role = localStorage.getItem("role");
    return role ? Promise.resolve(role) : Promise.reject();
  },
  logout: () => {
    localStorage.removeItem("token");
    localStorage.removeItem("refresh");
    localStorage.removeItem("role");
    return Promise.resolve();
  },
};

export default authProvider;
