import {
  ArrayField,
  ChipField,
  NumberField,
  Show,
  SimpleShowLayout,
  SingleFieldList,
  TextField,
} from "react-admin";

export const CouponShow = () => {
  return (
    <Show className="mb-4">
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="taking_class.nama" label="Class" sortable={false} />
        <TextField
          source="paket_class.nama"
          label="Paket Kelas"
          sortable={false}
        />
        <TextField
          source="cicilan_sesi_class.cicilan"
          label="Cicilan Sesi"
          sortable={false}
        />
        <NumberField source="discount" label="Discount" />
        <NumberField source="amount_used" label="Jumlah pemakaian" />
        <NumberField source="kelipatan_cicilan_class.kelipatan" />
        <NumberField source="jumlah_cicilan_class.cicilan" />
      </SimpleShowLayout>
    </Show>
  );
};
