export const PROCESSING_CHOICES = [
  { id: "PROCESSING", name: "PROCESSING" },
  { id: "SUCCESS", name: "SUCCESS" },
  { id: "REFUND", name: "REFUND" },
];
export const SUCCESS_CHOICES = [
  { id: "SUCCESS", name: "SUCCESS" },
  { id: "REFUND", name: "REFUND" },
];
export const STATUS_CHOICES = [
  { id: "PENDING", name: "PENDING" },
  { id: "PROCESSING", name: "PROCESSING" },
  { id: "SUCCESS", name: "SUCCESS" },
  { id: "REFUND", name: "REFUND" },
  { id: "CANCELLED", name: "CANCELLED" },
  { id: "EXPIRED", name: "EXPIRED" },
];
