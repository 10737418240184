import axios from "axios";

// Function to refresh the token
export const refreshToken = () => {
  const refresh = localStorage.getItem("refresh");
  return axios
    .post(`${process.env.REACT_APP_API_URL}/login/refresh/`, {
      refresh: refresh,
    })
    .then((res) => {
      var token = res.data?.access;
      var tokenRefresh = res.data?.refresh;
      localStorage.setItem("token", token);
      localStorage.setItem("refresh", tokenRefresh);
    })
    .catch((error) => {
      throw new Error(error);
    });
};
