export const CLASS_CREATION_MAKER = [
  {
    id: 1,
    nama: "Technology Integration in EducationKelas ",
    deskripsi:
      "This class focuses on designing products with a primary emphasis on user needs and experiences.",
    tentang:
      "Welcome to User-Centric Product Design! Learn to create innovative, user-friendly products through hands-on activities and real-world case studies. Explore principles like empathizing with users, ideation, iterative design, and usability. Join us to merge innovation with empathy and transform your product design approach!",
    status_kelas: "Approved",
    durasi_kelas: 3,
    total_sesi: 5,
    tipe_pembayaran: "Seikhlasnya",
    tipe_kelas: "Online",
    foto: "https://taman-school-dev.s3.ap-southeast-1.amazonaws.com/2c061879-9299-4a5c-b8f6-a7a8a7f37038-Screenshot%202024-01-25%20223624.png",
    jumlah_tutee: 0,
    tutees: [],
  },

  {
    id: 2,
    nama: "Literature and Digital Learning",
    deskripsi:
      "This class focuses on designing products with a primary emphasis on user needs and experiences.",
    tentang:
      "Welcome to User-Centric Product Design! Learn to create innovative, user-friendly products through hands-on activities and real-world case studies. Explore principles like empathizing with users, ideation, iterative design, and usability. Join us to merge innovation with empathy and transform your product design approach!Core Values bla bla bla",
    status_kelas: "Approved",
    durasi_kelas: 3,
    total_sesi: 5,
    tipe_pembayaran: "Seikhlasnya",
    tipe_kelas: "Hybrid",
    foto: "https://taman-school-dev.s3.ap-southeast-1.amazonaws.com/2c061879-9299-4a5c-b8f6-a7a8a7f37038-Screenshot%202024-01-25%20223624.png",
    jumlah_tutee: 15,
    tutees: [
      {
        id: 1,
        nama: "Olivia Johnson",
        email: "olivia.johnson@email.com",
        jumlah_sesi: 10,
        status: "Active",
      },
      {
        id: 2,
        nama: "Lily Davis",
        email: "lily.davis@email.com",
        jumlah_sesi: 10,
        status: "Active",
      },
      {
        id: 3,
        nama: "Grace Brown",
        email: "grace.brown@email.com",
        jumlah_sesi: 10,
        status: "Active",
      },
      {
        id: 4,
        nama: "Caleb Patel",
        email: "caleb.patel@email.com",
        jumlah_sesi: 10,
        status: "Active",
      },
      {
        id: 5,
        nama: "Ethan Rodriguez",
        email: "ethan.rodriguez@email.com",
        jumlah_sesi: 10,
        status: "Active",
      },
      {
        id: 6,
        nama: "Sophia Patel",
        email: "sophia.patel@email.com",
        jumlah_sesi: 10,
        status: "Active",
      },
      {
        id: 7,
        nama: "Lucas Singh",
        email: "lucas.singh@email.com",
        jumlah_sesi: 10,
        status: "Active",
      },
      {
        id: 8,
        nama: "Liam Chang",
        email: "liam.chang@email.com",
        jumlah_sesi: 10,
        status: "Active",
      },
      {
        id: 9,
        nama: "Noah Williams",
        email: "noah.williams@email.com",
        jumlah_sesi: 10,
        status: "Active",
      },
      {
        id: 10,
        nama: "Jackson Lee",
        email: "jackson.lee@email.com",
        jumlah_sesi: 10,
        status: "Active",
      },
      {
        id: 11,
        nama: "Mia Gupta",
        email: "mia.gupta@email.com",
        jumlah_sesi: 10,
        status: "Active",
      },
      {
        id: 12,
        nama: "Isabella Kim",
        email: "isabella.kim@email.com",
        jumlah_sesi: 10,
        status: "Active",
      },
      {
        id: 13,
        nama: "Aiden Patel",
        email: "aiden.patel@email.com",
        jumlah_sesi: 10,
        status: "Active",
      },
      {
        id: 14,
        nama: "Ava Nguyen",
        email: "ava.nguyen@email.com",
        jumlah_sesi: 10,
        status: "Active",
      },
      {
        id: 15,
        nama: "Emma Chen",
        email: "emma.chen@email.com",
        jumlah_sesi: 10,
        status: "Active",
      },
    ],
  },
  {
    id: 3,
    nama: "Literature-Based Educational Solutions",
    deskripsi:
      "This class focuses on designing products with a primary emphasis on user needs and experiences.",
    tentang:
      "Welcome to User-Centric Product Design! Learn to create innovative, user-friendly products through hands-on activities and real-world case studies. Explore principles like empathizing with users, ideation, iterative design, and usability. Join us to merge innovation with empathy and transform your product design approach!",
    status_kelas: "Approved",
    durasi_kelas: 3,
    total_sesi: 5,
    tipe_pembayaran: "Seikhlasnya",
    tipe_kelas: "Hybrid",
    foto: "https://taman-school-dev.s3.ap-southeast-1.amazonaws.com/2c061879-9299-4a5c-b8f6-a7a8a7f37038-Screenshot%202024-01-25%20223624.png",
    jumlah_tutee: 5,
    tutees: [
      {
        id: 1,
        nama: "Olivia Johnson",
        email: "olivia.johnson@email.com",
        jumlah_sesi: 10,
        status: "Active",
      },
      {
        id: 2,
        nama: "Lily Davis",
        email: "lily.davis@email.com",
        jumlah_sesi: 10,
        status: "Active",
      },
      {
        id: 3,
        nama: "Grace Brown",
        email: "grace.brown@email.com",
        jumlah_sesi: 10,
        status: "Active",
      },
      {
        id: 4,
        nama: "Caleb Patel",
        email: "caleb.patel@email.com",
        jumlah_sesi: 10,
        status: "Active",
      },
      {
        id: 5,
        nama: "Ethan Rodriguez",
        email: "ethan.rodriguez@email.com",
        jumlah_sesi: 10,
        status: "Active",
      },
    ],
  },
];
