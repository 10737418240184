export const PackageField = ({ label, symbol, value }) => {
  return (
    <div className="space-y-2">
      <p className="text-[#757575]">{label}</p>
      {label === "Class Packages" &&
        value.map((val, index) => (
          <div key={index} className="flex items-center space-x-4">
            <p className="w-[30%] text-[#006600] border rounded-lg px-3 py-2">
              {val.nama}
            </p>
            <div className="w-full flex items-center">
              <p className="text-black font-medium mr-1">{symbol}</p>
              <p className="w-full text-black border rounded-lg px-3 py-2">
                {val.harga}
              </p>
            </div>
          </div>
        ))}
      {label === "Installment Options" &&
        value.map((val, index) => (
          <div key={index} className="flex items-center space-x-4">
            <p className="w-[30%] text-[#006600] border rounded-lg px-3 py-2">
              {val.cicilan + "x"}
            </p>
            <div className="w-full flex items-center">
              <p className="text-black font-medium mr-1">{symbol}</p>
              <p className="w-full text-black border rounded-lg px-3 py-2">
                {val.diskon}
              </p>
            </div>
          </div>
        ))}
      {label === "Session Packages" &&
        value.map((val, index) => (
          <div key={index} className="flex items-center space-x-4">
            <p className="w-[30%] text-[#006600] border rounded-lg px-3 py-2">
              {val.sesi}
            </p>
            <div className="w-full flex items-center">
              <p className="text-black font-medium mr-1">{symbol}</p>
              <p className="w-full text-black border rounded-lg px-3 py-2">
                {val.diskon}
              </p>
            </div>
          </div>
        ))}
    </div>
  );
};
