export const KELAS_TYPES = [
  { id: "NDC", name: "NDC" },
  { id: "YEC", name: "YEC" },
  { id: "GEC", name: "GEC" },
  { id: "Others", name: "Others" },
];
export const PAKET_KELAS = [
  { id: "Private", name: "Private" },
  { id: "2 orang", name: "2 orang" },
  { id: "3 orang", name: "3 orang" },
  { id: "4 orang", name: "4 orang" },
];

export const PILIHAN_CICILAN_KELAS = [
  { id: 6, name: 6 },
  { id: 12, name: 12 },
  { id: 24, name: 24 },
  { id: 36, name: 36 },
  { id: 48, name: 48 },
  { id: 60, name: 60 },
  { id: 72, name: 72 },
  { id: 84, name: 84 },
  { id: 96, name: 96 },
];
