import {
  SingleFieldList,
  TextField,
  ArrayField,
  ImageField,
  Datagrid,
  ChipField,
  Show,
  TabbedShowLayout,
  RichTextField,
  DateField,
  useListContext,
} from "react-admin";

export const KelasShow = (props) => {
  const CicilanSesi = () => {
    const { data } = useListContext();
    return (
      <ul>
        {data.map((cicilan_sesi) => (
          <li key={cicilan_sesi.id}>{cicilan_sesi.cicilan}</li>
        ))}
      </ul>
    );
  };
  return (
    <Show {...props}>
      <TabbedShowLayout>
        <TabbedShowLayout.Tab label="Identity">
          <TextField source="id" />

          <TextField source="nama" />
          <TextField source="oleh" />
          <ImageField source="foto" />
          <TextField source="rating" />
        </TabbedShowLayout.Tab>

        <TabbedShowLayout.Tab label="Participants">
          <ArrayField source="tutor">
            <SingleFieldList linkType={false}>
              <ChipField source="user.nama" />
            </SingleFieldList>
          </ArrayField>

          <TextField source="jumlah_tutee" label="Total Tutees" />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Details">
          <TextField source="jumlah_sesi" />
          <ArrayField source="cicilan_sesi" label="Jumlah cicilan">
            <CicilanSesi />
          </ArrayField>

          <ArrayField source="core_values">
            <SingleFieldList linkType={false}>
              <ChipField source="nama" />
            </SingleFieldList>
          </ArrayField>
          <ArrayField source="paket_kelas">
            <Datagrid bulkActionButtons={false}>
              <TextField source="nama" />
              <TextField source="harga" />
            </Datagrid>
          </ArrayField>

          <DateField source="tanggal_tutup_pendaftaran" fullWidth />
          <RichTextField source="deskripsi" fullWidth />
          <RichTextField source="who_is_it_for" fullWidth />
          <RichTextField source="curriculum" fullWidth />
          <RichTextField source="features" fullWidth />
          <RichTextField source="timeline" fullWidth />
          <RichTextField
            source="info_penting"
            label="Informasi Penting"
            fullWidth
          />
          <ArrayField source="kelipatan_cicilan">
            <SingleFieldList linkType={false}>
              <ChipField source="kelipatan" />
            </SingleFieldList>
          </ArrayField>
          <ArrayField source="jumlah_cicilan">
            <SingleFieldList linkType={false}>
              <ChipField source="cicilan" />
            </SingleFieldList>
          </ArrayField>
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </Show>
  );
};
