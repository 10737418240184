import {
  ArrayInput,
  AutocompleteArrayInput,
  BooleanInput,
  Create,
  DateInput,
  ImageField,
  ImageInput,
  NumberInput,
  ReferenceArrayInput,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  required,
} from "react-admin";

import { KELAS_TYPES, PAKET_KELAS, PILIHAN_CICILAN_KELAS } from "src/constants";
import { uploadFileToS3, uploadMultipleFilesToS3 } from "../../utils/aws-s3";
import { RichTextInput } from "ra-input-rich-text";
import { Typography } from "@mui/material";

export const KelasCreate = (props) => {
  const PILIHAN_KELIPATAN_CICILAN = [];

  const transform = async (data) => {
    data.is_active = true;
    const fotoFile: File = data.foto.rawFile;
    const iconsFile: File[] = data.core_values.map(
      (value) => value.icon.rawFile
    );

    const fotoUrl = await uploadFileToS3(fotoFile);
    const iconsUrl = await uploadMultipleFilesToS3(iconsFile);

    data.foto = fotoUrl;
    data.core_values = data.core_values.map((value, index) => {
      return {
        ...value,
        icon: iconsUrl[index],
      };
    });
    if (data.tipe != "YEC") {
      const { jumlah_sesi, kelipatan_cicilan, ...newData } = data;
      return newData;
    } else {
      const { jumlah_cicilan, ...newData } = data;
      return newData;
    }
  };
  return (
    <Create
      {...props}
      title={"Create Kelas"}
      transform={transform}
      redirect="list"
    >
      <SimpleForm>
        <Typography variant="h5" className="py-4">
          Identity
        </Typography>
        <TextInput source="nama" validate={required()} />
        <SelectInput
          source="tipe"
          choices={KELAS_TYPES}
          validate={required()}
        />
        <TextInput source="oleh" required defaultValue={"TamanSiswa"} />
        <ImageInput source="foto" accept="image/*" validate={required()}>
          <ImageField source="src" title="title" />
        </ImageInput>

        <Typography variant="h5" className="py-4">
          Details
        </Typography>
        <RichTextInput source="deskripsi" fullWidth validate={required()} />
        <RichTextInput source="who_is_it_for" fullWidth validate={required()} />
        <RichTextInput source="curriculum" fullWidth validate={required()} />
        <RichTextInput source="features" fullWidth validate={required()} />
        <RichTextInput source="timeline" fullWidth validate={required()} />
        <RichTextInput source="info_penting" fullWidth validate={required()} />
        <ArrayInput source="core_values" validate={required()}>
          <SimpleFormIterator inline>
            <TextInput source="nama" validate={required()} />
            <TextInput source="deskripsi" validate={required()} />
            <ImageInput source="icon" accept="image/*" validate={required()}>
              <ImageField source="src" title="title" />
            </ImageInput>
          </SimpleFormIterator>
        </ArrayInput>
        <TextInput source="notion_url" validate={required()} />
        <ReferenceArrayInput source="tutor" reference="tutors">
          <AutocompleteArrayInput
            helperText="Multi-select"
            optionText="user.nama"
            optionValue="id"
          />
        </ReferenceArrayInput>
        <NumberInput
          source="jumlah_sesi"
          helperText="*required for type YEC"
          min={0}
        />
        <AutocompleteArrayInput
          source="kelipatan_cicilan"
          choices={PILIHAN_KELIPATAN_CICILAN}
          onCreate={(val) => {
            const newKelipatan = {
              id: val.toLowerCase(),
              name: val,
            };
            PILIHAN_KELIPATAN_CICILAN.push(newKelipatan);
            return newKelipatan;
          }}
        />
        <NumberInput
          source="durasi_kelas"
          helperText="Dalam jam dan per sesi"
          min={0}
          validate={required()}
        />
        <br />
        <ArrayInput source="paket_kelas" validate={required()}>
          <SimpleFormIterator inline>
            <SelectInput source="nama" choices={PAKET_KELAS} />
            <NumberInput source="harga" min={0} />
          </SimpleFormIterator>
        </ArrayInput>
        <AutocompleteArrayInput
          source="jumlah_cicilan"
          label="Jumlah cicilan"
          choices={PILIHAN_CICILAN_KELAS}
          onCreate={(val) => {
            const newCicilan = {
              id: parseInt(val.toLowerCase()),
              name: parseInt(val),
            };
            PILIHAN_CICILAN_KELAS.push(newCicilan);
            return newCicilan;
          }}
          validate={required()}
        />
        <DateInput
          source="tanggal_tutup_pendaftaran"
          helperText="Tanggal tutup pendaftaran"
          validate={required()}
        />

        <BooleanInput source="is_trending" defaultValue={false} />
      </SimpleForm>
    </Create>
  );
};
