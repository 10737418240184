import axios from "axios";
import { useEffect, useState } from "react";
import {
  SimpleForm,
  TextInput,
  Edit,
  Loading,
  useRecordContext,
  SelectInput,
  required,
  useGetOne,
  useGetRecordId,
  ReferenceArrayInput,
  AutocompleteInput,
  NumberInput,
} from "react-admin";

export const CouponEdit = (props) => {
  // const [selectedClassId, setSelectedClassId] = useState<number>();
  // const [paketClass, setPaketClass] = useState<any>();
  // const [cicilanSesi, setCicilanSesi] = useState<any>();

  // useEffect(() => {
  //   if (selectedClassId) {
  //     console.log("aa");
  //     axios
  //       .get(
  //         `${process.env.REACT_APP_RESOURCE_API_URL}/classes/detail/${selectedClassId}`
  //       )
  //       .then((res) => {
  //         setPaketClass(
  //           res.data.paket_kelas.map(({ id, nama }) => ({ id, name: nama }))
  //         ),
  //           setCicilanSesi(
  //             res.data.cicilan_sesi.map(({ id, cicilan }) => ({
  //               id,
  //               name: cicilan,
  //             }))
  //           );
  //       });
  //   }
  // }, [selectedClassId]);

  return (
    <Edit {...props}>
      <SimpleForm>
        <NumberInput source="discount" />
      </SimpleForm>
    </Edit>
  );
};
