import {
  Create,
  SimpleForm,
  TextInput,
  required,
  ReferenceInput,
  SelectInput,
  ImageInput,
  BooleanInput,
  NumberInput,
  CheckboxGroupInput,
  SelectArrayInput,
  ImageField,
  FileInput,
  FileField,
} from "react-admin";
import {
  DAYS,
  SUBJECTS,
  WEEKDAYS_CHOICES,
  WEEKEND_CHOICES,
} from "src/constants";
import { uploadFileToS3 } from "src/utils";

export const TutorCreate = (props) => {
  const transform = async (data) => {
    const {
      monday,
      tuesday,
      wednesday,
      thursday,
      friday,
      saturday,
      sunday,
      foto,
      rating,
      link_cv,
      link_portfolio,
      desired_class,
      ...restData
    } = data;

    const mergedSchedules = [
      monday ?? [],
      tuesday ?? [],
      wednesday ?? [],
      thursday ?? [],
      friday ?? [],
      saturday ?? [],
      sunday ?? [],
    ];

    restData.schedules = mergedSchedules
      .filter((day) => !!day)
      .map((day, idx) => ({
        type: DAYS[idx],
        schedule: day,
      }));

    const urlFoto = await uploadFileToS3(foto.rawFile);
    const urlCV = await uploadFileToS3(link_cv.rawFile);
    const urlPortfolio = link_portfolio?.rawFile
      ? await uploadFileToS3(link_portfolio.rawFile)
      : "";

    restData.foto = urlFoto;
    restData.link_cv = urlCV;
    if (urlPortfolio) restData.link_portfolio = urlPortfolio;
    if (desired_class !== 0) restData.desired_class = desired_class;

    return { ...restData };
  };
  return (
    <Create {...props} transform={transform}>
      <SimpleForm>
        <ReferenceInput source="user" reference="users" validate={required()}>
          <SelectInput optionText="nama" />
        </ReferenceInput>
        <ImageInput source="foto" accept="image/*" validate={required()}>
          <ImageField source="src" title="title" />
        </ImageInput>

        <TextInput source="description" multiline rows={2} fullWidth />
        <FileInput
          source="link_cv"
          label="CV"
          accept="application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          validate={required()}
        >
          <FileField source="src" title="title" />
        </FileInput>
        <FileInput
          source="link_portfolio"
          label="Portfolio"
          accept="application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        >
          <FileField source="src" title="title" />
        </FileInput>

        <TextInput source="linkedin_url" validate={required()} />
        <TextInput source="role" validate={required()} />
        <TextInput source="universitas" validate={required()} />
        <TextInput source="jurusan" validate={required()} />
        <BooleanInput source="is_accepted" validate={required()} />
        <NumberInput
          source="rating"
          max={5}
          min={0}
          step={0.1}
          defaultValue={0}
        />

        {/* class */}
        <SelectArrayInput
          source="subjects"
          choices={SUBJECTS}
          optionValue="name"
          validate={required()}
        />

        <ReferenceInput source="desired_class" reference="classes">
          <SelectInput optionText="nama" />
        </ReferenceInput>

        {/* schedule */}
        <CheckboxGroupInput
          source="monday"
          choices={WEEKDAYS_CHOICES}
          optionValue="name"
        />
        <CheckboxGroupInput
          source="tuesday"
          choices={WEEKDAYS_CHOICES}
          optionValue="name"
        />
        <CheckboxGroupInput
          source="wednesday"
          choices={WEEKDAYS_CHOICES}
          optionValue="name"
        />
        <CheckboxGroupInput
          source="thursday"
          choices={WEEKDAYS_CHOICES}
          optionValue="name"
        />
        <CheckboxGroupInput
          source="friday"
          choices={WEEKDAYS_CHOICES}
          optionValue="name"
        />
        <CheckboxGroupInput
          source="saturday"
          choices={WEEKEND_CHOICES}
          optionValue="name"
        />
        <CheckboxGroupInput
          source="sunday"
          choices={WEEKEND_CHOICES}
          optionValue="name"
        />
      </SimpleForm>
    </Create>
  );
};
