import { CouponCreate } from "./CouponCreate";
import { CouponEdit } from "./CouponEdit";
import { CouponList } from "./CouponList";
import { CouponShow } from "./CouponShow";

export default {
  list: CouponList,
  create: CouponCreate,
  edit: CouponEdit,
  show: CouponShow,
};
