import { KelasCreate } from "./KelasCreate";
import { KelasEdit } from "./KelasEdit";
import { KelasList } from "./KelasList";
import { KelasShow } from "./KelasShow";

export default {
  list: KelasList,
  create: KelasCreate,
  edit: KelasEdit,
  show: KelasShow,
};
