import {
  DashboardMenuItem,
  Menu,
  MenuItemLink,
  usePermissions,
} from "react-admin";

import { Box, Tooltip, Typography } from "@mui/material";
import { MENU_ADMIN, MENU_NON_ADMIN } from "src/constants";

export const MyMenu = (props) => {
  const { permissions } = usePermissions();
  const list = permissions === "ADMIN" ? MENU_ADMIN : MENU_NON_ADMIN.slice(-1);
  return (
    <Menu {...props}>
      <DashboardMenuItem />
      {list.map(({ groupName, menuItems }, idx) => {
        return (
          <Box className="mb-4" key={idx}>
            <Tooltip title={groupName} placement="right">
              <Typography
                variant="body1"
                id="closed"
                className="px-4 py-2 my-1 bg-primary/25"
              >
                {groupName.slice(0, 1)}
              </Typography>
            </Tooltip>
            <Typography
              variant="body1"
              id="open"
              className="px-4 py-2 my-1 bg-primary/25"
            >
              {groupName}
            </Typography>
            {menuItems.map((menu, idx) => {
              return <MenuItemLink key={idx} {...menu} />;
            })}
          </Box>
        );
      })}
    </Menu>
  );
};
