import {
  List,
  TextField,
  EditButton,
  DeleteWithConfirmButton,
  BooleanField,
  ShowButton,
  ArrayField,
  TextInput,
} from "react-admin";
import { MyDatagrid } from "../Elements";
import { Subjects } from "./Subjects";
export const TutorList = (props) => {
  const tutorFilters = [
    <TextInput label="Search" key={"tutor search"} source="q" alwaysOn />,
  ];
  return (
    <List {...props} filters={tutorFilters}>
      <MyDatagrid>
        <TextField source="id" sortable={false} />

        <TextField source="user.nama" label="Nama" sortable={false} />
        <TextField source="universitas" sortable={false} />
        <TextField source="jurusan" sortable={false} />
        <TextField
          source="desired_class.nama"
          label="Desired Class"
          sortable={false}
        />

        <ArrayField source="subjects" sortable={false}>
          <Subjects />
        </ArrayField>
        <BooleanField source="is_accepted" sortable={false} />
        <ShowButton />
        <EditButton />
        <DeleteWithConfirmButton />
      </MyDatagrid>
    </List>
  );
};
