import { stringify } from "query-string";
import { httpClient } from "./httpClient";
import axios, { AxiosResponse } from "axios";

import fakeDataProvider from "ra-data-fakerest";
import { CLASS_CREATION, CLASS_CREATION_MAKER } from "src/constants";

const apiUrl = `${process.env.REACT_APP_RESOURCE_API_URL}`;

export const dummyProvider = fakeDataProvider(
  {
    "class-creation": CLASS_CREATION,
    classmaker: CLASS_CREATION_MAKER,
  },
  true
);

export const customDataProvider = {
  getList: (resource, params) => {
    const { page, perPage } = params?.pagination;
    const { field, order } = params?.sort;
    const filter = params?.filter;

    const query = {
      current_page: page ?? 1,
      page_size: perPage ?? 10,
      ordering: `${order === "ASC" ? "" : "-"}${field}`,
      ...filter,
    };
    let url: string;
    if (resource == "coupons") {
      url = `${apiUrl}/transactions/coupon`;
      return httpClient.get(url).then((res) => {
        return {
          total: res.data?.length,
          data: res.data,
        };
      });
    } else {
      url = `${apiUrl}/${resource}/list?${stringify(query)}`;

      if (resource === "classes")
        url = `${apiUrl}/${resource}/list?${stringify(query)}&cms=true`;

      return httpClient.get(url).then((res) => {


        if (resource === "classrequests") {
          // Sort by status
          let order = { Pending: 1, Approved: 2, Declined: 3 };
          let hasil = res.data?.results.sort((a, b) => {
            return order[a.status_kelas] - order[b.status_kelas];
          });
          return {
            total: res.data?.count,
            data: hasil,
          };
        }
        return {
          total: res.data?.count,
          data: res.data?.results,
        };
      });
    }
  },

  getOne: (resource, params) => {
    let url = `${apiUrl}/${resource}/detail/${params.id}`;
    switch (resource) {
      case "users":
        url = `${apiUrl}/${resource}/information/${params.id}`;
        break;
      case "coupons":
        url = `${apiUrl}/transactions/coupon/${params.id}`;
        break;
    }
    return httpClient.get(url).then((res) => {
      return {
        data: res?.data,
      };
    });
  },

  getMany: (resource, params) => {
    const { filter, target, id } = params;
    const query = {
      ...filter,
      [target]: id,
    };
    return httpClient
      .get(`${apiUrl}/${resource}/list?${stringify(query)}`)
      .then((res) => ({
        total: res.data?.length,
        data: res.data,
      }));
  },

  getManyReference: (resource, params) => {
    const { filter, target, id } = params;
    const query = {
      ...filter,
      [target]: id,
    };
    return httpClient
      .get(`${apiUrl}/${resource}/list?${stringify(query)}`)
      .then((res) => ({
        total: res.data?.length,
        data: res.data,
      }));
  },

  create: (resource, params) => {
    let url = `${apiUrl}/${resource}/new`;
    switch (resource) {
      case "tutors":
        url = `${apiUrl}/${resource}/add/${params.data.user}`;
        break;
      case "coupons":
        url = `${apiUrl}/transactions/coupon`;
        break;
      default:
        break;
    }
    return httpClient.post(`${url}`, params.data).then((res) => ({
      data: res.data,
    }));
  },
  update: (resource, params) => {
    let url = `${apiUrl}/${resource}/detail/${params.id}`;
    var data = params.data;
    switch (resource) {
      case "coupons":
        url = `${apiUrl}/transactions/coupon/${params.id}`;
        data = { discount: params.data.discount };
        break;
      default:
        break;
    }
    return httpClient.patch(url, data).then((res) => ({
      data: { ...res.data, id: res.data.id },
    }));
  },

  updateMany: (resource, params) => {
    const query = {
      filter: { id: params.ids },
      data: params.data,
    };
    return httpClient.patch(`${apiUrl}/${resource}`, query).then((res) => ({
      data: res.data,
    }));
  },

  delete: (resource, params) => {
    let url = `${apiUrl}/${resource}/detail/${params.id}`;
    switch (resource) {
      case "coupons":
        url = `${apiUrl}/transactions/coupon/${params.id}`;
        break;
      default:
        break;
    }
    return httpClient.delete(url).then((res) => ({
      data: res?.data,
    }));
  },

  deleteMany: (resource, params) => {
    const deleteRequests = params.ids.map((id) =>
      httpClient.delete(`${apiUrl}/${resource}/detail/${id}/`)
    );

    return axios.all(deleteRequests).then((responses: AxiosResponse[]) => ({
      data: responses.map((response) => response.data),
    }));
  },

  editTrendingClass: (classId) => {
    return axios
      .post(`${apiUrl}/classes/edit-trending`, {
        class_id: classId,
      })
      .then((response) => response.data);
  },

  updateClassRequest: (classId, status) => {
    return httpClient
      .post(`${apiUrl}/classrequests/edit-status`, {
        class_id: classId,
        status_kelas: status,
      })
      .then((res) => {
        data: res?.data;
      });
  },
};
