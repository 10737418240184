import {
  ArrayInput,
  AutocompleteArrayInput,
  AutocompleteInput,
  BooleanInput,
  DateInput,
  Edit,
  ImageField,
  ImageInput,
  Loading,
  NumberInput,
  ReferenceArrayInput,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  required,
  useDataProvider,
  useGetOne,
  useGetRecordId,
  useRecordContext,
} from "react-admin";
import { KELAS_TYPES, PILIHAN_CICILAN_KELAS } from "../../constants";

import { RichTextInput } from "ra-input-rich-text";
import { Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { uploadFileToS3, urlToFile, uploadMultipleFilesToS3 } from "src/utils";

export const KelasEdit = (props) => {
  const classId = useGetRecordId();
  const [fotoUrl, setFotoUrl] = useState("");
  const [iconsUrl, setIconsUrl] = useState<string[]>([]);
  const dataProvider = useDataProvider();
  const [record, setRecord] = useState(null);

  const [paketKelas, setPaketKelas] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const [pilihanKelipatan, setPilihanKelipatan] = useState<any[]>([]);
  useEffect(() => {
    const fetchRecord = async () => {
      try {
        const { data } = await dataProvider.getOne("classes", {
          id: classId,
        });

        const icons: string[] = data.core_values.map((value) => value.icon);
        setFotoUrl(data.foto);
        setIconsUrl(icons);
        data.foto = await urlToFile(data.foto);
        data.core_values = await Promise.all(
          data.core_values.map(async (value, index) => {
            return {
              ...value,
              icon: await urlToFile(value.icon),
            };
          })
        );

        data.tutors = data.tutor.map((t) => t.id);
        data.jumlah_cicilan = data.jumlah_cicilan.map((jc) => jc.cicilan);
        data.kelipatan_cicilan = data.kelipatan_cicilan.map((kc) => {
          return kc.kelipatan;
        });
        setPilihanKelipatan(
          data.kelipatan_cicilan.map((kc) => {
            return {
              id: kc,
              name: kc,
            };
          })
        );
        setPaketKelas(data.paket_kelas);

        setRecord(data);

        setIsLoading(false);
      } catch (error) {
        // Handle error
      }
    };

    fetchRecord();
  }, [dataProvider, props.id]);

  const transform = async (data) => {
    const fotoFile: File = data.foto.rawFile;
    const fotoUrlRes = !fotoFile ? fotoUrl : await uploadFileToS3(fotoFile);

    const iconsUrlRes = await Promise.all(
      data.core_values.map(async (value) =>
        value.icon instanceof File
          ? iconsUrl.find((icon) => icon.includes(value.icon.name))
          : await uploadFileToS3(value.icon.rawFile)
      )
    );

    data.is_active = true;
    data.foto = fotoUrlRes;
    data.core_values = data.core_values.map((value, index) => {
      return {
        ...value,
        icon: iconsUrlRes[index],
      };
    });
    data.tutor =
      data.tutors.length == 0 ? data.tutor.map((t) => t.id) : data.tutors;

    // set value that classcreation field needed
    if (data.tipe !== "Others"){
      data.tipe_kelas = "";
      data.tipe_pembayaran = "";
    }

    if (data.tipe != "YEC") {
      const { jumlah_sesi, kelipatan_cicilan, ...newData } = data;
      return newData;
    } else {
      const { jumlah_cicilan, ...newData } = data;
      return newData;
    }

    // return data;
  };
  if (record == null) return <Loading />;

  return (
    <Edit {...props} transform={transform} mutationMode="optimistic">
      <SimpleForm record={record}>
        <Typography variant="h5" className="py-4">
          Identity
        </Typography>
        <TextInput source="nama" validate={required()} />
        <SelectInput
          source="tipe"
          choices={KELAS_TYPES}
          validate={required()}
        />
        <TextInput source="oleh" required defaultValue={"TamanSiswa"} />
        <ImageInput source="foto" accept="image/*">
          <ImageField source="src" title="title" />
        </ImageInput>

        <Typography variant="h5" className="py-4">
          Details
        </Typography>
        <RichTextInput source="deskripsi" fullWidth validate={required()} />
        <RichTextInput source="who_is_it_for" fullWidth />
        <RichTextInput source="curriculum" fullWidth />
        <RichTextInput source="features" fullWidth />
        <RichTextInput source="timeline" fullWidth />
        <RichTextInput source="info_penting" fullWidth />
        <ArrayInput source="core_values">
          <SimpleFormIterator inline>
            <TextInput source="nama" validate={required()} />
            <TextInput source="deskripsi" validate={required()} />
            <ImageInput source="icon" accept="image/*">
              <ImageField source="src" title="title" />
            </ImageInput>
          </SimpleFormIterator>
        </ArrayInput>
        <TextInput source="notion_url" />
        <ReferenceArrayInput source="tutors" reference="tutors">
          <AutocompleteArrayInput
            helperText="Multi-select"
            optionText="user.nama"
            optionValue="id"
          />
        </ReferenceArrayInput>
        <NumberInput
          source="jumlah_sesi"
          helperText="*required for type YEC"
          min={0}
        />
        <AutocompleteArrayInput
          source="kelipatan_cicilan"
          choices={pilihanKelipatan}
          onCreate={(val) => {
            const newKelipatan = {
              id: val.toLowerCase(),
              name: val,
            };
            pilihanKelipatan.push(newKelipatan);
            return newKelipatan;
          }}
        />
        <NumberInput
          source="durasi_kelas"
          helperText="Dalam jam dan per sesi"
          min={0}
        />
        <br />
        <ArrayInput
          source="paket_kelas"
          validate={record.tipe == "Others" ? undefined : required()}
        >
          <SimpleFormIterator inline>
            <SelectInput
              isLoading={isLoading}
              source="nama"
              choices={paketKelas}
              optionText="nama"
              optionValue="nama"
            />
            <NumberInput source="harga" min={0} />
          </SimpleFormIterator>
        </ArrayInput>
        <AutocompleteArrayInput
          label="Jumlah cicilan"
          source="jumlah_cicilan"
          choices={PILIHAN_CICILAN_KELAS}
          onCreate={(val) => {
            const newCicilan = {
              id: parseInt(val.toLowerCase()),
              name: parseInt(val),
            };
            PILIHAN_CICILAN_KELAS.push(newCicilan);
            return newCicilan;
          }}
        />

        <DateInput
          source="tanggal_tutup_pendaftaran"
          helperText="Tanggal tutup pendaftaran"
          validate={required()}
        />

        <BooleanInput source="is_trending" defaultValue={false} />
      </SimpleForm>
    </Edit>
  );
};
