import { FC } from "react";
import { Datagrid, DatagridProps } from "react-admin";

export const MyDatagrid: FC<DatagridProps> = (props) => {
  const { bulkActionButtons, children, ...restProps } = props;
  return (
    <Datagrid
      {...restProps}
      bulkActionButtons={props.bulkActionButtons ?? false}
      sx={{
        "& .RaDatagrid-headerCell": {
          fontWeight:550,
        },
      }}
    >
      {props.children}
    </Datagrid>
  );
};
