// In theme.js
import { defaultTheme } from "react-admin";

import merge from "lodash/merge";
import { createTheme } from "@mui/material";

export const myTheme = createTheme(
  merge({}, defaultTheme, {
    palette: {
      primary: {
        main: "#005700",
      },
      secondary: {
        main: "#DEF8DE",
      },
    },
    components: {
      ...defaultTheme.components,
      MuiTextField: {
        defaultProps: {
          variant: "outlined",
        },
      },
      MuiFormControl: {
        defaultProps: {
          variant: "outlined",
        },
      },

      RaMenu: {
        styleOverrides: {
          root: {
            marginTop: 0,
            "&.RaMenu-closed .MuiTypography-body1#open": {
              display: "none",
            },
            "&.RaMenu-open .MuiTypography-body1#closed": {
              display: "none",
            },
            "&.RaMenu": {
              transition: "ease-in-out",
              transitionDelay: "1s",
            },
            ".MuiTypography-body1": {
              fontWeight: 550,
              color: "#1E281E",
            },
          },
        },
      },

      RaMenuItemLink: {
        styleOverrides: {
          root: {
            // invisible border when not active, to avoid position flashs
            borderLeft: "3px solid transparent",
            "&.RaMenuItemLink-active": {
              borderLeft: "10px solid #FFC300",
            },
            "& .RaMenuItemLink-icon": {
              color: "#005700",
            },
          },
        },
      },
    },
  })
);
