import { useEffect, useState } from "react";
import {
  Edit,
  TextInput,
  required,
  NumberInput,
  BooleanInput,
  ReferenceInput,
  SelectInput,
  ImageInput,
  SelectArrayInput,
  CheckboxGroupInput,
  useDataProvider,
  useGetRecordId,
  ImageField,
  FileInput,
  FileField,
  TabbedForm,
  Loading,
} from "react-admin";
import { toast } from "react-hot-toast";
import {
  DAYS,
  SUBJECTS,
  WEEKDAYS_CHOICES,
  WEEKEND_CHOICES,
} from "src/constants";
import { uploadFileToS3, urlToFile } from "src/utils";

export const TutorEdit = (props) => {
  const [record, setRecord] = useState(null);
  const [fotoUrl, setFotoUrl] = useState("");
  const [CVUrl, setCVUrl] = useState("");
  const [portoUrl, setPortoUrl] = useState("");

  const dataProvider = useDataProvider();
  const tutorId = useGetRecordId();

  useEffect(() => {
    const fetchRecord = async () => {
      try {
        const { data } = await dataProvider.getOne("tutors", {
          id: tutorId,
        });

        setFotoUrl(data.foto);
        setCVUrl(data.link_cv);
        setPortoUrl(data.link_portfolio ?? "");
        data.foto = await urlToFile(data.foto);
        data.link_cv = await urlToFile(data.link_cv);
        data.link_portfolio = !!data.link_portfolio
          ? await urlToFile(data.link_portfolio)
          : "";
        data.schedules.forEach((daySchedule) => {
          switch (daySchedule.type) {
            case "MONDAY":
              data.monday = daySchedule.schedule;
              break;
            case "TUESDAY":
              data.tuesday = daySchedule.schedule;
              break;
            case "WEDNESDAY":
              data.wednesday = daySchedule.schedule;
              break;
            case "THURSDAY":
              data.thursday = daySchedule.schedule;
              break;
            case "FRIDAY":
              data.friday = daySchedule.schedule;
              break;
            case "SATURDAY":
              data.saturday = daySchedule.schedule;
              break;
            case "SUNDAY":
              data.sunday = daySchedule.schedule;
              break;
            default:
              break;
          }
        });

        setRecord(data);
      } catch (error) {
        // Handle error
        toast.error(error.message);
      }
    };

    fetchRecord();
  }, [dataProvider, props.id]);

  const transform = async (data) => {
    const {
      monday,
      tuesday,
      wednesday,
      thursday,
      friday,
      saturday,
      sunday,
      foto,
      link_cv,
      link_portfolio,
      user,
      desired_class,
      ...restData
    } = data;

    const {
      id,
      profile_picture,
      nama_ortu,
      no_telp_ortu,
      email_ortu,
      ...restUser
    } = user;

    restData.user = restUser;
    const mergedSchedules = [
      monday ?? [],
      tuesday ?? [],
      wednesday ?? [],
      thursday ?? [],
      friday ?? [],
      saturday ?? [],
      sunday ?? [],
    ];

    restData.schedules = mergedSchedules.map((day, idx) => ({
      type: DAYS[idx],
      schedule: day,
    }));

    const urlFoto = !foto.rawFile
      ? fotoUrl
      : await uploadFileToS3(foto.rawFile);
    const urlCV = !link_cv.rawFile
      ? CVUrl
      : await uploadFileToS3(link_cv.rawFile);
    const urlPortfolio = !link_portfolio?.rawFile
      ? portoUrl
      : await uploadFileToS3(link_portfolio?.rawFile);

    restData.foto = urlFoto;
    restData.link_cv = urlCV;
    if (urlPortfolio) restData.link_portfolio = urlPortfolio;
    if (desired_class?.id) restData.desired_class = desired_class.id;

    return { ...restData };
  };
  return (
    <Edit {...props} mutationMode="optimistic" transform={transform}>
      {!!record ? (
        <TabbedForm record={record}>
          <TabbedForm.Tab label={"User Information"}>
            <TextInput source="user.nama" label="Nama" validate={required()} />
            <TextInput
              source="user.no_telp"
              label="No Telp"
              validate={required()}
            />
            <TextInput
              source="user.pekerjaan"
              label="Pekerjaan"
              validate={required()}
            />
            <TextInput
              source="user.asal_sekolah"
              label="Asal Sekolah"
              validate={required()}
            />
          </TabbedForm.Tab>

          <TabbedForm.Tab label={"Tutor Information"}>
            <ImageInput source="foto" accept="image/*" validate={required()}>
              <ImageField source="src" title="title" />
            </ImageInput>

            <TextInput source="description" multiline />
            <FileInput
              source="link_cv"
              label="CV"
              accept="application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              validate={required()}
            >
              <FileField source="src" title="title" target="_blank" />
            </FileInput>
            <FileInput
              source="link_portfolio"
              label="Portfolio"
              accept="application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            >
              <FileField source="src" title="title" target="_blank" />
            </FileInput>
            <TextInput source="linkedin_url" validate={required()} />
            <TextInput source="role" validate={required()} />
            <TextInput source="universitas" validate={required()} />
            <TextInput source="jurusan" validate={required()} />
            <BooleanInput source="is_accepted" validate={required()} />
            <NumberInput source="rating" max={5} min={0} step={0.1} />

            {/* class */}
            <SelectArrayInput
              source="subjects"
              choices={SUBJECTS}
              optionValue="name"
              validate={required()}
            />

            <ReferenceInput
              source="desired_class.id"
              label="Desired Class"
              reference="classes"
              validate={required()}
            >
              <SelectInput optionText="nama" />
            </ReferenceInput>

            {/* schedule */}
            <CheckboxGroupInput
              source="monday"
              choices={WEEKDAYS_CHOICES}
              optionValue="name"
            />
            <CheckboxGroupInput
              source="tuesday"
              choices={WEEKDAYS_CHOICES}
              optionValue="name"
            />
            <CheckboxGroupInput
              source="wednesday"
              choices={WEEKDAYS_CHOICES}
              optionValue="name"
            />
            <CheckboxGroupInput
              source="thursday"
              choices={WEEKDAYS_CHOICES}
              optionValue="name"
            />
            <CheckboxGroupInput
              source="friday"
              choices={WEEKDAYS_CHOICES}
              optionValue="name"
            />
            <CheckboxGroupInput
              source="saturday"
              choices={WEEKEND_CHOICES}
              optionValue="name"
            />
            <CheckboxGroupInput
              source="sunday"
              choices={WEEKEND_CHOICES}
              optionValue="name"
            />
          </TabbedForm.Tab>
        </TabbedForm>
      ) : (
        <Loading />
      )}
    </Edit>
  );
};
