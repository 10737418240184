import { Typography } from "@mui/material";
import { AppBar, TitlePortal } from "react-admin";

const MyAppBar = (props) => {
  return (
    <AppBar
      {...props}
      sx={{
        backgroundColor: "#005700",
      }}
    >
      <TitlePortal />
      <Typography variant="h6" id="react-admin-title">
        Taman School CMS &nbsp;
      </Typography>
    </AppBar>
  );
};

export default MyAppBar;
