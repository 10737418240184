import {
  Datagrid,
  DeleteWithConfirmButton,
  EditButton,
  ImageField,
  List,
  NumberField,
  ShowButton,
  TextField,
} from "react-admin";

export const CouponList = ({ props }) => {
  return (
    <List {...props}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="id" />
        <TextField source="taking_class.nama" label="Class" sortable={false} />
        <TextField
          source="paket_class.nama"
          label="Paket Kelas"
          sortable={false}
        />
        <TextField
          source="cicilan_sesi_class.cicilan"
          label="Cicilan Sesi"
          sortable={false}
        />

        <NumberField source="discount" label="Discount" />
        <NumberField source="amount_used" label="Jumlah pemakaian" />
        <ShowButton />
        <EditButton />
        <DeleteWithConfirmButton />
      </Datagrid>
    </List>
  );
};
