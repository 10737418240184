// import jwt_decode from "jsonwebtoken";
import jwt_decode from "jwt-decode";

type jwtTokenType = {
  exp: number;
};

export const getTokenExpiry = (token: string) => {
  try {
    const decodedToken: jwtTokenType = jwt_decode(token);
    const expiry = new Date(decodedToken.exp * 1000 - 10000); // Multiply by 1000 to convert seconds to milliseconds

    return expiry;
  } catch (error) {
    return null;
  }
};

export const isTokenNearExpired = (token: string) => {
  const NOW = new Date();

  if (!!getTokenExpiry(token)) {
    return NOW.getTime() >= getTokenExpiry(token).getTime();
  }
  return false;
};
