import AWS from "aws-sdk";
import { v4 as uuidv4 } from "uuid";

const s3 = new AWS.S3({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY_VALUE,
  region: process.env.REACT_APP_AWS_REGION,
});

export const uploadFileToS3 = async (file: File) => {
  let url: string = "";

  const key = `${uuidv4()}-${file.name}`;
  const bucket: string = process.env.REACT_APP_AWS_BUCKET_NAME;

  const params = {
    Bucket: bucket,
    Key: key,
    Body: file,
    ContentType: file.type,
  };

  try {
    const result = await s3.upload(params).promise();
    const uploadedUrl = new URL(result.Location);
    url = `${process.env.REACT_APP_AWS_BUCKET_HOSTNAME}${uploadedUrl.pathname}`;
  } catch (error) {
    console.error(error);
    throw Error("Error uploading files. Please try again.");
  }
  return url;
};

export const uploadMultipleFilesToS3 = async (files: File[]) => {
  const urls: string[] = [];
  for (const file of files) {
    const key = `${uuidv4()}-${file.name}`;
    const bucket: string = process.env.REACT_APP_AWS_BUCKET_NAME;

    const params = {
      Bucket: bucket,
      Key: key,
      Body: file,
      ContentType: file.type,
    };

    try {
      const result = await s3.upload(params).promise();
      const uploadedUrl = new URL(result.Location);
      const url = `${process.env.REACT_APP_AWS_BUCKET_HOSTNAME}${uploadedUrl.pathname}`;
      urls.push(url);
    } catch (error) {
      console.error(error);
      throw Error("Error uploading files. Please try again.");
    }
  }
  return urls;
};
