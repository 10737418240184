import {
  SimpleForm,
  TextInput,
  Edit,
  Loading,
  useRecordContext,
  SelectInput,
  required,
  useGetOne,
  useGetRecordId,
} from "react-admin";

import {
  PROCESSING_CHOICES,
  STATUS_CHOICES,
  SUCCESS_CHOICES,
} from "src/constants";

const StatusChoices = () => {
  const record = useRecordContext();
  return (
    <>
      {record.status === "PROCESSING" && (
        <SelectInput
          source="status"
          choices={PROCESSING_CHOICES}
          validate={required()}
        />
      )}
      {record.status === "SUCCESS" && (
        <SelectInput
          source="status"
          choices={SUCCESS_CHOICES}
          validate={required()}
        />
      )}
      {record.status !== "PROCESSING" && record.status !== "SUCCESS" && (
        <SelectInput source="status" choices={STATUS_CHOICES} disabled />
      )}
    </>
  );
};
export const TransactionEdit = (props) => {
  const transactionId = useGetRecordId();
  const { data: initialData } = useGetOne("transactions", {
    id: transactionId,
  });
  const transform = async (data) => {
    const { kelompok, status } = data;
    if (status === initialData.status) {
      return { nama_kelompok: kelompok };
    }
    return { nama_kelompok: kelompok, status: status };
  };
  return (
    <Edit {...props} mutationMode="optimistic" transform={transform}>
      {!!initialData ? (
        <SimpleForm sanitizeEmptyValues={false}>
          <TextInput disabled source="user.nama" label="User" />
          <TextInput source="kelompok" />
          <StatusChoices />
          <TextInput disabled source="real_price" />
          <TextInput disabled source="discount" />
          <TextInput disabled source="coupon" />
          <TextInput disabled source="taking_class.nama" label="Nama Kelas" />
        </SimpleForm>
      ) : (
        <Loading />
      )}
    </Edit>
  );
};
