import { List, TextField, ShowButton, Show, ArrayField } from "react-admin";
import { ArrowSmallRightIcon } from "@heroicons/react/24/solid";
import { StatusField } from "../CustomFields";
import { searchFilters } from "../../filters";
import { MyDatagrid } from "../Elements";
import clsx from "clsx";

export const CreatedClassShow = (props) => {
  return (
    <Show {...props} filters={searchFilters}>
      <ArrayField source="tutee">
        <MyDatagrid>
          <TextField source="id" label="Id" sortable={false} />
          <TextField source="tutee__user__information__nama" label="Nama Tutee" sortable={false} />
          <TextField source="tutee__user__email" label="Email" sortable={false} />
          <TextField
            source="tutee__user__transactions__jumlah_sesi"
            label="Jumlah Sesi"
            sortable={false}
            textAlign="center"
          />
          <StatusField label="Status" />
          <ShowButton
            icon={<ArrowSmallRightIcon width={24} />}
            alignIcon="right"
            label="Show Details"
          />
        </MyDatagrid>
      </ArrayField>
    </Show>
  );
};
