import {
  AcademicCapIcon,
  BuildingLibraryIcon,
  CreditCardIcon,
  PresentationChartBarIcon,
  TagIcon,
  UsersIcon,
  PuzzlePieceIcon,
} from "@heroicons/react/24/solid";

const ICON_WIDTH = 24;
export const MENU_ADMIN = [
  {
    groupName: "Accounts",
    menuItems: [
      {
        primaryText: "Users",
        to: "/users",
        leftIcon: <UsersIcon width={ICON_WIDTH} />,
      },
    ],
  },
  {
    groupName: "Class Information",
    menuItems: [
      {
        primaryText: "Tutees",
        to: "/tutees",
        leftIcon: <AcademicCapIcon width={ICON_WIDTH} />,
      },
      {
        primaryText: "Tutors",
        to: "/tutors",
        leftIcon: <PresentationChartBarIcon width={ICON_WIDTH} />,
      },
      {
        primaryText: "Class",
        to: "/classes",
        leftIcon: <BuildingLibraryIcon width={ICON_WIDTH} />,
      },
      {
        primaryText: "Class Creation",
        to: "/classrequests",
        leftIcon: <PuzzlePieceIcon width={ICON_WIDTH} />,
      },
      {
        primaryText: "Transactions",
        to: "/transactions",
        leftIcon: <CreditCardIcon width={ICON_WIDTH} />,
      },
      {
        primaryText: "Coupons",
        to: "/coupons",
        leftIcon: <TagIcon width={ICON_WIDTH} />,
      },
    ],
  },
];

export const MENU_NON_ADMIN = [
  {
    groupName: "Class Information",
    menuItems: [
      {
        primaryText: "Tutees",
        to: "/tutees",
        leftIcon: <AcademicCapIcon width={ICON_WIDTH} />,
      },
      {
        primaryText: "Tutors",
        to: "/tutors",
        leftIcon: <PresentationChartBarIcon width={ICON_WIDTH} />,
      },
      {
        primaryText: "Class",
        to: "/classes",
        leftIcon: <BuildingLibraryIcon width={ICON_WIDTH} />,
      },
      {
        primaryText: "Class Creation",
        to: "/classrequests",
        leftIcon: <PuzzlePieceIcon width={ICON_WIDTH} />,
      },
      {
        primaryText: "Transactions",
        to: "/transactions",
        leftIcon: <CreditCardIcon width={ICON_WIDTH} />,
      },
      {
        primaryText: "Coupons",
        to: "/coupons",
        leftIcon: <TagIcon width={ICON_WIDTH} />,
      },
    ],
  },
];
