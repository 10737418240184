import path from "path";

export const urlToFile = async (url) => {
    const response = await fetch(url);
    const blob = await response.blob();

    const parsedUrl = new URL(url);

    const fileName = parsedUrl.pathname.split('/').pop();// Implement your function to extract the file name from the URL
    const file = new File([blob], fileName, { type: blob.type });
    return file;
};