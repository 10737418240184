import React, { ReactNode } from "react";

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  rightIcon?: any;
  leftIcon?: any;
  children?: ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  isLoading?: boolean;
  variant?: "approve" | "decline";
}

export const Button: React.FC<ButtonProps> = ({
  className,
  children,
  onClick,
  leftIcon,
  rightIcon,
  disabled,
  isLoading,
  variant,
  ...props
}) => {
  return (
    <button
      {...props}
      className={`border flex items-center text-center justify-center gap-3 rounded-3xl px-4 py-2 font-semibold 
      ${className}
    ${variant === "decline" && "border-[#EEB4B0] bg-[#FFF4F2] text-[#D73A36]"}
    ${variant === "approve" && "border-[#AACCAA] bg-[#F5FFF6] text-[#6DB657]"}
      `}
      onClick={onClick}
      disabled={disabled || isLoading}
    >
      {leftIcon && <span className="stroke-current">{leftIcon}</span>}
      {isLoading ? (
        <div className="border-inherit h-5 w-5 animate-spin rounded-full border-b-2"></div>
      ) : (
        children
      )}
      {rightIcon && <span className="stroke-current">{rightIcon}</span>}
    </button>
  );
};
