import { Layout } from "react-admin";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import { Breadcrumbs, Link } from "@mui/material";
import MyAppBar from "./AppBar";
import { MyMenu } from "./Menu";

export const MyLayout = ({ children, props }) => {
  const routes = [{ path: "/", breadcrumb: "Dashboard" }];
  const breadcrumbs = useBreadcrumbs(routes);

  return (
    <Layout {...props} appBar={MyAppBar} menu={MyMenu}>
      <Breadcrumbs className="pt-4">
        {breadcrumbs.map(({ breadcrumb, key }, idx) => (
          <Link
            href={key}
            key={key}
            color={"#fffff"}
            underline="none"
            className={`${
              breadcrumbs.length - 1 == idx
                ? "text-green-800"
                : "text-slate-500"
            }`}
          >
            {breadcrumb}
          </Link>
        ))}
      </Breadcrumbs>
      {children}
    </Layout>
  );
};
