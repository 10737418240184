import { List, TextField, ShowButton, BooleanField } from "react-admin";
import { searchFilters } from "../../filters";
import { MyDatagrid } from "../Elements";
export const UserList = (props) => {
  return (
    <List {...props} filters={searchFilters}>
      <MyDatagrid>
        <TextField source="id" sortable={false} />
        <TextField source="nama" sortable={false} />
        <TextField source="role" sortable={false} />
        <TextField source="no_telp" label="Nomor Telepon" sortable={false} />
        <TextField source="nama_ortu" label="Nama Orang Tua" sortable={false} />
        <TextField source="asal_sekolah" sortable={false} />
        <TextField source="pekerjaan" sortable={false} />
        <BooleanField source="is_admin" sortable={false} />
        <ShowButton />
      </MyDatagrid>
    </List>
  );
};
