import {
  TextField,
  ChipField,
  SingleFieldList,
  UrlField,
  Show,
  TabbedShowLayout,
  ArrayField,
  FunctionField,
  ImageField,
} from "react-admin";

export const TuteeShow = (props) => {
  return (
    <Show {...props}>
      <TabbedShowLayout>
        <TabbedShowLayout.Tab label="Data Tutee">
          <TextField source="id" />
          <TextField source="user.nama" label="User" />
          <TextField source="user.email" label="Email" />
          <ImageField source="user.profile_picture" label="Profile Picture" />
          <TextField source="user.no_telp" label="No HP" />
          <TextField source="user.asal_sekolah" label="Asal Sekolah" />
          <TextField source="user.pekerjaan" label="Pekerjaan" />
        </TabbedShowLayout.Tab>

        <TabbedShowLayout.Tab label="Data Orang Tua">
          <TextField source="user.nama_ortu" label="Nama Orang Tua" />
          <TextField source="user.no_telp_ortu" label="No HP Orang Tua" />{" "}
          <TextField source="user.email_ortu" label="Email Orang Tua" />{" "}
        </TabbedShowLayout.Tab>

        <TabbedShowLayout.Tab label="Kelas">
          <UrlField source="notion_url" />

          <ArrayField source="kelas_berlangsung" label="Kelas Berlangsung">
            <SingleFieldList linkType={false}>
              <ChipField source="nama" />
            </SingleFieldList>
          </ArrayField>

          <ArrayField source="kelas_selesai" label="Kelas Selesai">
            <SingleFieldList linkType={false}>
              <ChipField source="nama" />
            </SingleFieldList>
          </ArrayField>

          <FunctionField
            label="Active"
            render={(record) =>
              `${record?.kelas_berlangsung?.length > 0 ? "True" : "False"}`
            }
          />
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </Show>
  );
};
