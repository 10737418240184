import { Checkbox } from "@mui/material";

const BoxIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="20" height="20" rx="4" fill="#EDEDED" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.09617 13.7789L8.09702 13.7797C8.23414 13.9154 8.41397 13.9844 8.59377 13.9844C8.77358 13.9844 8.95341 13.9154 9.09053 13.7797L15.6543 7.21595C15.9281 6.94076 15.9281 6.49677 15.6543 6.22159L15.6535 6.22074C15.3783 5.94696 14.9338 5.9474 14.6587 6.22118L8.59378 12.287L5.34095 9.03324C5.06577 8.75946 4.62177 8.75946 4.34659 9.03324L4.34575 9.03409C4.07197 9.30927 4.07239 9.75369 4.34617 10.0289L8.09617 13.7789Z"
      fill="#404040"
    />
  </svg>
);

const TextIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2 4.75C2 4.33579 2.33579 4 2.75 4H17.25C17.6642 4 18 4.33579 18 4.75C18 5.16421 17.6642 5.5 17.25 5.5H2.75C2.33579 5.5 2 5.16421 2 4.75ZM2 15.25C2 14.8358 2.33579 14.5 2.75 14.5H10.25C10.6642 14.5 11 14.8358 11 15.25C11 15.6642 10.6642 16 10.25 16H2.75C2.33579 16 2 15.6642 2 15.25Z"
      fill="#404040"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2 10C2 9.58579 2.33579 9.25 2.75 9.25H17.25C17.6642 9.25 18 9.58579 18 10C18 10.4142 17.6642 10.75 17.25 10.75H2.75C2.33579 10.75 2 10.4142 2 10Z"
      fill="#404040"
    />
  </svg>
);

export const CustomClassForm = ({ value }) => {
  return (
    <div className="mb-2">
      <p className="text-[#757575]">Class Form</p>
      {value.map((val, index) => (
        <div
          key={index}
          className="border rounded-lg text-black font-medium flex-col py-2 px-4 mb-3"
        >
          <div className="flex flex-row items-center space-x-10">
            <p className="text-black border-b py-2 w-1/2">{val.pertanyaan}</p>
            <div className="border rounded-lg w-1/2 flex items-center p-3">
              {val.tipe_form === "Text" ? <TextIcon /> : <BoxIcon />}
              <p className="text-black ml-2">{val.tipe_form}</p>
            </div>
          </div>

          {val.checkboxes.length > 0 &&
            val.checkboxes.map((option, index) => (
              <div key={index} className="flex items-center gap-x-1">
                <Checkbox disabled value={option} />
                <p>{option}</p>
              </div>
            ))}
        </div>
      ))}
    </div>
  );
};
