import { useEffect, useState } from "react";
import {
  SimpleForm,
  TextInput,
  Edit,
  ImageInput,
  useDataProvider,
  useGetRecordId,
  Loading,
  ImageField,
  required,
  ArrayInput,
  SimpleFormIterator,
  BooleanInput,
  AutocompleteInput,
} from "react-admin";
import toast from "react-hot-toast";
import { uploadFileToS3, urlToFile } from "src/utils";

export const TuteeEdit = (props) => {
  const dataProvider = useDataProvider();
  const tuteeId = useGetRecordId();
  const [profileUrl, setProfileUrl] = useState("");
  const [record, setRecord] = useState(null);
  const [ongoing, setOngoing] = useState([]);
  const [finished, setFinished] = useState([]);

  useEffect(() => {
    const fetchRecord = async () => {
      try {
        const { data } = await dataProvider.getOne("tutees", {
          id: tuteeId,
        });

        setProfileUrl(data.user.profile_picture ?? "");
        data.user.profile_picture = !!data.profile_picture
          ? await urlToFile(data.user.profile_picture)
          : "";

        const ongoing = data.kelas_berlangsung.map(({ id, nama }) => {
          return { id: id, name: nama };
        });
        const finished = data.kelas_selesai.map(({ id, nama }) => {
          return { id: id, name: nama };
        });

        setOngoing(ongoing);
        setFinished(finished);

        setRecord(data);
      } catch (error) {
        toast.error(error.message);
      }
    };

    fetchRecord();
  }, [dataProvider, props.id]);
  const transform = async (data) => {
    const {
      kelas_berlangsung,
      kelas_selesai,
      id,
      active_status,
      user,
      ongoing,
      finished,
      ...restData
    } = data;
    const { profile_picture, ...restUser } = user;
    const urlFoto = !profile_picture.rawFile
      ? profileUrl
      : await uploadFileToS3(profile_picture.rawFile);

    if (urlFoto) restUser.profile_picture = urlFoto;

    for (let key in restUser) {
      if (!restUser[key]) {
        delete restUser[key];
      }
    }
    let taking_class = [];
    if (finished?.length > 0) {
      taking_class = [...finished];
    }
    if (ongoing?.length > 0) {
      if (taking_class.length > 0) {
        taking_class = [...taking_class, ...ongoing];
      } else {
        taking_class = [...ongoing];
      }
    }
    if (taking_class.length > 0) {
      restData.taking_class = taking_class;
    }

    restData.user = restUser;

    return {
      ...restData,
    };
  };

  return (
    <Edit {...props} mutationMode="optimistic" transform={transform}>
      {!!record ? (
        <SimpleForm sanitizeEmptyValues={false} record={record}>
          <TextInput source="user.nama" label="Nama" validate={required()} />
          <TextInput source="user.email" label="Email" validate={required()} />
          <ImageInput
            source="user.profile_picture"
            accept="image/*"
            label="Profile Picture"
          >
            <ImageField source="src" title="title" />
          </ImageInput>
          <TextInput
            source="user.no_telp"
            label="No HP"
            validate={required()}
          />
          <TextInput
            source="user.asal_sekolah"
            label="Asal Sekolah"
            validate={required()}
          />
          <TextInput
            source="user.pekerjaan"
            label="Pekerjaan"
            validate={required()}
          />

          <TextInput source="user.nama_ortu" label="Nama Orang tua" />
          <TextInput source="user.no_telp_ortu" label="No HP Orang tua" />
          <TextInput source="user.email_ortu" label="Email Orang tua" />
          <TextInput source="notion_url" />
          <ArrayInput source="ongoing" label="Kelas Berlangung">
            <SimpleFormIterator inline>
              <AutocompleteInput
                source="class_id"
                choices={ongoing}
                validate={required()}
              />
              <BooleanInput
                source="is_active"
                defaultValue={true}
                validate={required()}
              />
            </SimpleFormIterator>
          </ArrayInput>
          <ArrayInput source="finished" label="Kelas Selesai">
            <SimpleFormIterator inline>
              <AutocompleteInput
                source="class_id"
                choices={finished}
                validate={required()}
              />
              <BooleanInput
                source="is_active"
                defaultValue={false}
                validate={required()}
              />
            </SimpleFormIterator>
          </ArrayInput>
        </SimpleForm>
      ) : (
        <Loading />
      )}
    </Edit>
  );
};
