import { TutorCreate } from "./TutorCreate";
import { TutorEdit } from "./TutorEdit";
import { TutorList } from "./TutorList";
import { TutorShow } from "./TutorShow";

export default {
  list: TutorList,
  create: TutorCreate,
  edit: TutorEdit,
  show: TutorShow,
};
