export const CLASS_CREATION = [
  {
    id: 1,
    nama: "Technology Integration in EducationKelas ",
    deskripsi:
      "This class focuses on designing products with a primary emphasis on user needs and experiences.",
    tentang:
      "Welcome to User-Centric Product Design! Learn to create innovative, user-friendly products through hands-on activities and real-world case studies. Explore principles like empathizing with users, ideation, iterative design, and usability. Join us to merge innovation with empathy and transform your product design approach!",
    oleh: "Amelia Rodriguez",
    status: "Waiting list",
    durasi_kelas: 3,
    total_sesi: 5,
    tipe_pembayaran: "Seikhlasnya",
    tipe_kelas: "Online",
    foto: "https://taman-school-dev.s3.ap-southeast-1.amazonaws.com/2c061879-9299-4a5c-b8f6-a7a8a7f37038-Screenshot%202024-01-25%20223624.png",
  },
  {
    id: 2,
    nama: "Literature and Digital Learning",
    deskripsi:
      "This class focuses on designing products with a primary emphasis on user needs and experiences.",
    tentang:
      "Welcome to User-Centric Product Design! Learn to create innovative, user-friendly products through hands-on activities and real-world case studies. Explore principles like empathizing with users, ideation, iterative design, and usability. Join us to merge innovation with empathy and transform your product design approach!Core Values bla bla bla",
    oleh: "Amelia Rodriguez",
    status: "Approved",
    durasi_kelas: 3,
    total_sesi: 5,
    tipe_pembayaran: "Seikhlasnya",
    tipe_kelas: "Hybrid",
    foto: "https://taman-school-dev.s3.ap-southeast-1.amazonaws.com/2c061879-9299-4a5c-b8f6-a7a8a7f37038-Screenshot%202024-01-25%20223624.png",

  },
  {
    id: 3,
    nama: "Literature-Based Educational Solutions",
    deskripsi:
      "This class focuses on designing products with a primary emphasis on user needs and experiences.",
    tentang:
      "Welcome to User-Centric Product Design! Learn to create innovative, user-friendly products through hands-on activities and real-world case studies. Explore principles like empathizing with users, ideation, iterative design, and usability. Join us to merge innovation with empathy and transform your product design approach!",
    oleh: "Xavier Patel",
    status: "Declined",
    durasi_kelas: 3,
    total_sesi: 5,
    tipe_pembayaran: "Seikhlasnya",
    tipe_kelas: "Hybrid",
    foto: "https://taman-school-dev.s3.ap-southeast-1.amazonaws.com/2c061879-9299-4a5c-b8f6-a7a8a7f37038-Screenshot%202024-01-25%20223624.png",
  },
];
